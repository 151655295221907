body{ 
  height: 100vh; margin:0; 
}

/* Trick */
#root { 
  height: 100vh; margin:0; 
  display:flex; 
  flex-direction:column; 
}

.clear {
  margin: 0!important;
  padding: 0!important;
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.unit {
  color: rgb(216, 108, 20);
}

.main-spinner {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

th {
  font-weight: 600;
}
.table-document {
  /*border-top: 1px solid rgba(126, 122, 117, 0.534);
  border-left: 1px solid rgba(126, 122, 117, 0.534);
  border-right: 1px solid rgba(126, 122, 117, 0.534);*/
  background-color: #FFF;
  box-shadow: 4px 6px 11px 4px rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 40px;
}
.required-field::after {
  content: "*";
  color: red;
}

.extraButton {
  width: max-content;
}
.cell-breakWord {
  width: '50%';
  word-wrap: break-word;
  max-width: 1px;
  -webkit-hyphens: auto; /* iOS 4.2+ */
  -moz-hyphens: auto; /* Firefox 5+ */
  -ms-hyphens: auto; /* IE 10+ */
  hyphens: auto;
}
.container-main {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left:  calc(var(--bs-gutter-x) * .5);
  margin-top: 15px;
}
.row {
  align-content: center;
}
.row-header {
  font-size: .925em; /*   font-size: .925em; */
}
.row-main {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
  font-size: .925em;
  background-color: #f2f2f2;

  min-height: 42px;
}
.row-main-sequence {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
  font-size: .925em;
  background-color: #ffd40078;
  /* background-color: #ffc10787; #5e00a52b #0042a540 #5e00a52b */
  min-height: 42px;
}
.row-main-start {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
  font-size: .925em;
  font-weight: 500;
  background-color: #f2f2f2;
  min-height: 42px;
}
.row-main-end {
  margin-bottom: 20px;
}
.row-main-even {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
  font-size: .925em;
  background-color: #fff;
  min-height: 42px;
}

.container-main2 {
  padding-right: 0;
  padding-left:  calc(var(--bs-gutter-x) * .5);
  margin-top: 15px;
  margin-right: 0;
}
.row-main2 {
  font-size: .925em;
  margin-right: 0;
  background-color: rgb(38 119 154/12%);
  border-radius: '8px';
}
.row-main-even2 {
  margin-right: 0;
  font-size: .925em;
}

.text-end{
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.header-title{
  position: absolute;
  text-align: center;
}
.print {
  visibility: hidden;
  height: 0;
  display: none;
}
.hidden {
  visibility: hidden;
  height: 0;
  display: none;
}
.printButton {
  justify-content: flex-end;
  display: flex;
  align-content: flex-end;
  margin-left: auto;
  /* margin-right: auto; */
  position: relative;
  margin-top: -8px;
  /* margin-top: 3px; */
  right: 0;
  float: right;
}
.languageButton {
  position: relative;
  /* margin-right: 15px; 110px; */
  /* margin-top: 3px; */
  right: 0;
  margin-right: 15px;
  float: right;
}

@media print {
  .noprint {
     visibility: hidden;
     height: 0;
     display: none;
  }
  .print {
    font-size: .675em;
    visibility: visible;
    display: block;
    height: auto;
  } 
  body {
    zoom: 60%;
  }
  .table-result-details {
    font-size: .925em;
    --bs-table-striped-bg: #00000010 !important;
    border-color: #fff !important;
    -webkit-print-color-adjust: exact;
  }
  .table-document {
    border: none;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
    margin-bottom: 20px;
  }
}

.table-result {
  font-size: .925em;
}

.table-result-details {
  font-size: .925em;
  --bs-table-striped-bg: rgb(38 119 154 / 10%);
  border-color: rgb(38 119 154 / 10%);
}

.search-input{
  font-size: 1.45rem;
  height: '65px';
}
.search-select {
  font-size: 1.0rem;
  height: '65px'!important;
}

/*
.alert-display{
  font-size: .925em;
	position: absolute;
  width: 100%;
  z-Index: 100;
  opacity: 0.75;
  margin-top: 10px 
}
*/
.app-header {
  background: #0072b8ad;
  background: -webkit-linear-gradient(to right, #0072b88a, rgba(37, 117, 252, 0.9));
  background: linear-gradient(to right, #0072b88a, rgba(37, 117, 252, 0.9));
  /*
  background: #0071b8;
  background: linear-gradient(180deg, rgba(0,113,184,1) 0%, rgba(0,113,184,1) 35%, rgba(0,113,184,0.5256477591036415) 100%);
  background-repeat: repeat-x; */

  /*background: #6a11cb;
  background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 0.9), rgba(37, 117, 252, 0.9));
  background: linear-gradient(to right, rgba(106, 17, 203, 0.9), rgba(37, 117, 252, 0.9));
  */

  /* border-top: #0071b8 solid thick; */
  /* border-bottom: #0071b8 solid thick; */
  /*mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f2faff 75%, #b0e2ff 25%, rgba(255, 255, 255, 0) 100%);*/
  /* margin: 0 auto; */
  padding: 3px 0;
}

/*
nav ul li.current {
	background: #392CAB;
}

nav ul li {
  display: inline-block;
  background: #5231C2;
}

fullwidth
{
  width: 670px;
}

nav ul li a {
  padding: 14px;
  text-transform:uppercase;
  color:#FFFFFF;
  font-size: 18px;
  display: block;
}


nav ul li a:hover {
  border-top-right-radius:75px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
  box-decoration-break:clone;
  background: rgba(255, 255, 255, 0.5);
  color: blueviolet;
}
*/
/*
.loading
{
  text-align: center;
  font-size: 72px;
  font-weight: 900;
  background-color: antiquewhite;
  padding-top: 20%;
}

.padding-left20
{
  margin-left: 50%;
}

.padding10
{
  margin-left: 50px;
}

.textcenter
{
  text-align: center!important;
  align-content: center!important;
  padding-left: 20%;
}
*/
.textcenter2
{
  text-align: center!important;
  align-content: center!important;
}
/*
.textcenter3
{

  padding-left: 20%!important;
}

.inline-btn
{
  display: inline-block!important;
}

.paddingleft-10
{
  padding-left: 3%!important;
}

.btn-sub
{
  height: 32px!important;
}

.bold{
  border: 2px solid !important;
}

.scroll
{
  overflow:scroll;
  height:600px;
}

#additional {
  width: 100%;
  padding: 50px 0;
  text-align: center;
  background-color: lightblue;
  margin-top: 20px;
  display: none;
}



.one-word-per-line
{
  word-spacing: 100vw;
  white-space: pre-line;
}
*/
/* .break{
  width: min-intrinsic;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: table-caption;
  display: -ms-grid;
  -ms-grid-columns: min-content;
} */
/*
.helpicon {
  position: relative;
  display: inline-block;

}

.helpicon .helpcontent {
  visibility: hidden;
  width: auto!important;
  background-color:#e5f1e5;
  color:#000000;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -470px;
  opacity: 0;
  transition: opacity 1s;
text-align:left;
*/
  /* white-space: nowrap; */
/*
}

.helpicon .helpcontent::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 90%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(8, 8, 8) transparent transparent transparent;
}

.helpicon:hover .helpcontent {
  visibility: visible;
  opacity: 1;
}
*/
/* CSS for Footer */

#myFooter {
  /* background: rgb(0,113,184);
  background: linear-gradient(0deg, rgb(0 113 184) 0%, rgb(0 113 184 / 25%) 15%, rgb(0 113 184 / 2%) 100%); */
  background: #0072b8ad;
  background: -webkit-linear-gradient(to right, #0072b88a, rgba(37, 117, 252, 0.9));
  background: linear-gradient(to right, #0072b88a, rgba(37, 117, 252, 0.9));
  color: white;
  padding-top: 30px;
  margin-top: auto;
}
@media screen and (max-width: 767px) {
  #myFooter {
      text-align: center;
  }
}


.logo-one
{
  width: '100px';
  float: left;
}

.logo-two
{
  float: right;
}

#myFooter .footer-copyright {
  /* background: rgb(0,113,184);
  background: linear-gradient(0deg, rgb(0 113 184) 0%, rgb(0 113 184 / 25%) 15%, rgb(0 113 184 / 2%) 100%); */
  padding-top: 2px;
  padding-bottom: 30px;
  text-align: center;
}
#myFooter .footer-copyright p {
  margin: 2px;
  color:#FFF;
  font-weight:bold;
}

/*
#myFooter .row {
    margin-bottom: 60px;
}

#myFooter .navbar-brand {
    margin-top: 45px;
    height: 65px;
}

#myFooter ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 1.7;
}

#myFooter h5 {
    font-size: 18px;
    color: white;
    font-weight: bold;
    margin-top: 30px;
}

#myFooter h2 a{
    font-size: 50px;
    text-align: center;
    color: #fff;
}

#myFooter a {
    color:#FFFFFF;
    text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
  border-radius:15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
  box-decoration-break:clone;
  background: rgba(255, 255, 255, 0.5);
  color: blueviolet;
	padding: 5px;
}

#myFooter .social-networks {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 16px;
}

#myFooter .social-networks a {
    font-size: 32px;
    color: #f9f9f9;
    padding: 10px;
    transition: 0.2s;
}

#myFooter .social-networks a:hover {
    text-decoration: none;
}

#myFooter .facebook:hover {
    color: #0077e2;
}

#myFooter .google:hover {
    color: #ef1a1a;
}

#myFooter .twitter:hover {
    color: #00aced;
}
*/

/* CSS for Footer */